import { toast } from "react-toastify";
import axios from "axios";
import { useEffect, useState } from "react";
import Spinner from "./../_Components/Spinner"
import { validateEmail } from "./../utils"
import { MIN_LENGTH_PASSWORD, TENANT_ID_AMADORI } from "./../consts"
import Root from "../Root";


function PasswordDimenticata() {
  const [loading, setLoading] = useState();
  const [currentStep, setCurrentStep] = useState(0);


  return (
    <>
      <Root />
      <div className="container">
        <form className="mt-5 mx-auto" style={{ maxWidth: 550 }} onSubmit={async (e) => {
          e.preventDefault();
          const formData = Object.values(e.target)?.map(f => {
            return ({ name: f.name, value: f.type === "checkbox" ? f.checked : f.value })
          }).filter(f => !!f.name)

          let payload = {}
          formData.forEach(formDataItem => {
            payload[formDataItem.name] = formDataItem.value
          });
          console.log({ payload })

          if (currentStep === 0) {
            if (!payload["email"] || !validateEmail(payload["email"])) {
              toast("Inserisci un'indirizzo email valido", {
                type: "warning"
              })
              return
            }

            try {
              await axios.post(`https://api.loyaltyone.io/api/v1/auth/forgot-password`, {
                email: payload["email"],
                tenantId: TENANT_ID_AMADORI
              })
              setCurrentStep(1)
            } catch (error) {
              toast("Si è verificato un errore, si prega di riprovare più tardi", {
                type: "error"
              })
            }

          } else if (currentStep === 1) {
            if (!payload["code"]) {
              toast("Inserisci il codice che hai ricevuto per mail", {
                type: "warning"
              })
              return
            }

            if (payload["password"]?.length < MIN_LENGTH_PASSWORD) {
              toast(`Inserisci una password valida (almeno ${MIN_LENGTH_PASSWORD} caratteri)`, {
                type: "warning"
              })
              return
            }
            if (payload["password"] !== payload["conferma-password"]) {
              toast("Le due password non coincidono", {
                type: "warning"
              })
              return
            }


            try {
              await axios.post(`https://api.loyaltyone.io/api/v1/auth/forgot-password-confirm`, {
                email: payload["email"],
                tenantId: TENANT_ID_AMADORI,
                newPassword: payload["password"],
                code: payload["code"]
              })
              toast("Complimenti, hai modificato la password correttamente", {
                type: "success"
              })

              window.location.href = "https://ilcampeseoro.amadori.it";

            } catch (error) {
              toast("Si è verificato un errore, si prega di riprovare più tardi", {
                type: "error"
              })
            }

          }



        }}>

          {
			  [
				<div key="email" className={`form-group ${currentStep === 0 ? "" : "d-none"}`}>
				  <label htmlFor="exampleInputEmail1">Indirizzo email</label>
				  <input name="email" type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Inserisci l'email" />
				</div>,
                <div key="code" className={`form-group ${currentStep === 1 ? "" : "d-none"}`}>
                  <label htmlFor="code">Codice ricevuto
                  <span className="helpCode">{"Controlla la tua email per verificare il codice necessario alla reimpostazione della password"}</span>
                  </label>
                  <input name="code" type="text" className="form-control" id="code" aria-describedby="codeHelp" placeholder="Inserisci il codice ricevuto" />
                </div>,
                <div key="psw" className={`form-group ${currentStep === 1 ? "" : "d-none"}`}>
                  <label htmlFor="password">Password</label>
                  <input name="password" type="password" className="form-control" id="password" placeholder="Inserisci la password" />
                </div>
                ,
                <div key="conf-psw" className={`form-group ${currentStep === 1 ? "" : "d-none"}`}>
                  <label htmlFor="password-confirm">Conferma password</label>
                  <input name="conferma-password" type="password" className="form-control" id="password-confirm" placeholder="Conferma password" />
                </div>
              ]
          }


          <div className="mt-3">
            <button disabled={loading}
              type="submit"
              className={`btn btn-primary w-100`}
              style={{ opacity: loading ? 0.5 : 1 }}
              id="btn-submit">
              {!loading ? (currentStep === 0 ? "Ottieni codice" : "Reset password") : <Spinner />}
            </button>
          </div>
        </form>

      </div>
    </>
  );
}

export default PasswordDimenticata;
