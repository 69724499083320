import { Outlet } from "react-router-dom";

export default function Root() {
  return (
    <div className="container">
      <div style={{ marginTop: 50, display:"flex", justifyContent:"center" }}>
        <img src='logo.svg' style={{ maxWidth: 200 }} />
        <img src='logo_c_oro.svg' style={{ maxWidth: 50, marginLeft:10 }} />
      </div>
      <Outlet />
    </div>
  );
}