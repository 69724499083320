import './App.css';
import {
  Route,
  Routes,
  BrowserRouter,
  Navigate,
} from "react-router-dom";
import Root from "./Root"
import Registrazione from "./Registrazione"
import PasswordDimenticata from "./PasswordDimenticata"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <div style={{display: "flex", justifyContent:"center", flexDirection: "column", alignItems: "center"}}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate replace to="/registrazione" />} />
        <Route path="registrazione" element={<Registrazione />} />
        <Route path="password-dimenticata" element={<PasswordDimenticata />} />
      </Routes>
    </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
